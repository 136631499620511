.sidebar {
  margin-left: 240px;
}

.under-construction {
  background-image: url('../../modules/src/assets/images/under-construction.png');
  background-repeat: repeat-x;
  background-size: 42px 5px;
  position: fixed;
  height: 5px;
  width: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
}
