.toast-error {
  color: var(--chakra-colors-_text-error);
}

.toast-action-button {
  color: var(--chakra-colors-_text-invertPrimary);
  background-color: var(--chakra-colors-_element-linkPrimary);
  padding: 3px 9px;
  border-radius: 6px;
  font-size: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.toast-action-button:hover {
  opacity: 0.8;
}
