@keyframes infinity-scroll-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.logoList {
  display: flex;
  animation: infinity-scroll-right 80s infinite linear 0.5s both;
}
